<template>
  <div class="dropdown__wrapper">
    <div class="dropdown__title-wrapper">
      <div class="dropdown__title" @click="showList">
        <span v-if="value">{{ value }}</span>
        <span v-else>
          <slot></slot>
        </span>
      </div>
    </div>
    <div v-show="show" class="dropdown__list-wrapper">
      <ul class="dropdown__list">
        <li
          v-for="(option, i) in options"
          :key="i"
          class="dropdown__item"
          @click="emit(option)"
        >{{ option }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dropdown',
  props: {
    value: {
      type: String,
      default: null
    },
    options: {
      type: Array,
      default: null
    },
    position: {
      type: String,
      default: 'top'
    }
  },
  data: () => ({
    show: false
  }),
  watch: {
    show() {
      const list = this.$el.childNodes[1]
      const doc = this.$root.$el
      if (this.show) {
        setTimeout(() => {
          doc.addEventListener('click', outsideClickListener)
        }, 0)
      }
      function isVisible(list) {
        return (
          !!list &&
          !!(
            list.offsetWidth ||
            list.offsetHeight ||
            list.getClientRects().length
          )
        )
      }
      const outsideClickListener = event => {
        if (!this.show) {
          list.style = 'display: none'
          doc.removeEventListener('click', outsideClickListener)
        }
        if (!list.contains(event.target) && isVisible(list)) {
          this.show = false
          list.style = 'display: none'
          doc.removeEventListener('click', outsideClickListener)
        }
      }
    }
  },
  mounted() {
    // const windowWidth = window.innerWidth
    // const windowHeight = window.innerHeight
    // button parameters
    // const btn = document.querySelector('.dropdown__title')
    // const btnWidth = btn.getBoundingClientRect().width
    // const btnHeight = btn.getBoundingClientRect().height
    // const btnX = btn.getBoundingClientRect().x
    // const btnY = btn.getBoundingClientRect().y
    // const btnRight = btn.getBoundingClientRect().right
    // const btnBottom = btn.getBoundingClientRect().bottom
    // const btnReverseX = windowWidth - btnRight
    // const btnReverseY = windowHeight - btnBottom
    // console.log(this)
    // list parameters
    // const list = document.getElementById('list')
    // const listWidth = btn.getBoundingClientRect().width
    // const listHeight = btn.getBoundingClientRect().height
    // if (btnReverseX > listWidth) {
    //   console.log('ok')
    // } else {
    //   console.log('not ok')
    // }
    // console.log(btnReverseX)
    // console.log(btn.getBoundingClientRect())
  },
  methods: {
    emit(option) {
      this.$emit('action', option)
      this.show = !this.show
    },
    showList() {
      this.show = !this.show
      // const windowWidth = window.innerWidth
      const windowHeight = window.innerHeight
      const btn = this.$el.childNodes[0]
      const btnBottom = btn.getBoundingClientRect().bottom
      const btnReverseBottom = windowHeight - btnBottom
      // const btnRight = btn.getBoundingClientRect().right // length from left side page to right side button
      // const btnReverseX = windowWidth - btnRight
      // const btnX = btn.getBoundingClientRect().x
      // const btnWidth = btn.getBoundingClientRect().width
      const list = this.$el.childNodes[1]
      if (this.show) {
        // setTimeout(() => {
        //   const listWidth = list.getBoundingClientRect().width
        //   if (btnReverseX > listWidth && this.show) {
        //     list.style.right = -listWidth - 20 + 'px'
        //     list.style.opacity = 1
        //   } else if (btnReverseX < listWidth && this.show) {
        //     list.style.right = btnWidth + 50 + 'px'
        //     list.style.opacity = 1
        //   }
        // }, 0)

        setTimeout(() => {
          const listHeight = list.getBoundingClientRect().height
          // if (btnReverseX > listWidth && this.show) {
          //   list.style.right = -listWidth - 20 + 'px'
          //   list.style.opacity = 1
          // } else if (btnReverseX < listWidth && this.show) {
          //   list.style.right = btnWidth + 50 + 'px'
          //   list.style.opacity = 1
          // }
          // list.style.right = 0
          // list.style.top = 0
          if (btnReverseBottom < listHeight) {
            list.style.bottom = '100%'
          }
          list.style.opacity = 1
        }, 0)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
