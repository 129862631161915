<template>
  <div class="table__row" :class="{'table__row--dec': !info.active}">
    <div class="table__cell">
      <toggle-button color="#0290FC" :width="40" :sync="true" v-model="info.active" />
    </div>
    <div class="table__cell">
      <div class="table__cell-title">{{ info.name }}</div>
      <div class="table__cell-subtitle">{{ info.email }}</div>
    </div>
    <div class="table__cell">
      <div class="table__cell-title">{{ info.phone }}</div>
    </div>
    <div class="table__cell table__cell--horizontal">
      <span class="table__cell-title" v-for="shift in info.shifts" :key="shift.id">{{ shift.name }}</span>
    </div>
    <div class="table__cell table__cell--dropdown">
      <Dropdown :v-model="value" :options="options" @action="action">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8 12.5C8 13.3284 7.32843 14 6.5 14C5.67157 14 5 13.3284 5 12.5C5 11.6716 5.67157 11 6.5 11C7.32843 11 8 11.6716 8 12.5ZM14 12.5C14 13.3284 13.3284 14 12.5 14C11.6716 14 11 13.3284 11 12.5C11 11.6716 11.6716 11 12.5 11C13.3284 11 14 11.6716 14 12.5ZM18.5 14C19.3284 14 20 13.3284 20 12.5C20 11.6716 19.3284 11 18.5 11C17.6716 11 17 11.6716 17 12.5C17 13.3284 17.6716 14 18.5 14Z" fill="#333A56"/>
        </svg>
      </Dropdown>
    </div>
  </div>
</template>

<script>
import {ToggleButton} from 'vue-js-toggle-button'
import Dropdown from '@/components/Dropdown'
export default {
  name: 'Driver',
  components: {
    ToggleButton,
    Dropdown
  },
  props: {
    info: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    show: false,
    value: 'dropdown',
    options: ['edit', 'open', 'stripe', 'xwasssdasd']
  }),
  methods: {
    action(act) {
      console.log(act)
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
.wr {
  position: absolute;
  bottom: 0;
  right: 0;
  border: 1px solid red;
}
.table__row {
  position: relative;
}
</style>
