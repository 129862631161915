<template>
  <div class="page-wrapper drivers__wrapper">
    <div class="container">
      <div class="drivers__inner page-inner">
        <div class="drivers__title-wrapper page-title-wrapper">
          <div class="drivers__title page-title">Drivers</div>
          <div @click="showAddDriver" class="drivers__button button button--fill">Add New Driver</div>
        </div>
        <div class="drivers__table table">
          <div class="table__title">
            <div class="table__title-item">On/Off</div>
            <div class="table__title-item">User Name</div>
            <div class="table__title-item">Phone number</div>
            <div class="table__title-item">Available Shifts</div>
          </div>
          <div class="table__main">
            <Driver v-for="driver in drivers" :key="driver.id" :info="driver" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Driver from '@/views/Admin/Drivers/Driver'

export default {
  name: 'Drivers',
  components: {Driver},
  data: () => ({
    drivers: [
      {
        id: 1,
        active: true,
        name: 'Rodrix Fasteners',
        email: 'johnycash@mail.me',
        phone: '+23 232 23 23',
        shifts: [
          {id: 1, name: '🌙  Night Shift'},
          {id: 2, name: '🌈  Pride Shift'}
        ]
      },
      {
        id: 2,
        active: true,
        name: 'Rodrix Fasteners',
        email: 'johnycash@mail.me',
        phone: '+23 232 23 23',
        shifts: [
          {id: 1, name: '🌙  Night Shift'},
          {id: 2, name: '🌈  Pride Shift'}
        ]
      },
      // {
      //   id: 3,
      //   active: true,
      //   name: 'Rodrix Fasteners',
      //   email: 'johnycash@mail.me',
      //   phone: '+23 232 23 23',
      //   shifts: [
      //     {id: 1, name: '🌙  Night Shift'},
      //     {id: 2, name: '🌈  Pride Shift'}
      //   ]
      // },
      // {
      //   id: 4,
      //   active: true,
      //   name: 'Rodrix Fasteners',
      //   email: 'johnycash@mail.me',
      //   phone: '+23 232 23 23',
      //   shifts: [
      //     {id: 1, name: '🌙  Night Shift'},
      //     {id: 2, name: '🌈  Pride Shift'}
      //   ]
      // },
      // {
      //   id: 5,
      //   active: true,
      //   name: 'Rodrix Fasteners',
      //   email: 'johnycash@mail.me',
      //   phone: '+23 232 23 23',
      //   shifts: [
      //     {id: 1, name: '🌙  Night Shift'},
      //     {id: 2, name: '🌈  Pride Shift'}
      //   ]
      // },
      // {
      //   id: 6,
      //   active: false,
      //   name: 'Rodrix Fasteners',
      //   email: 'johnycash@mail.me',
      //   phone: '+23 232 23 23',
      //   shifts: [
      //     {id: 1, name: '🌙  Night Shift'},
      //     {id: 2, name: '🌈  Pride Shift'}
      //   ]
      // },
      // {
      //   id: 7,
      //   active: true,
      //   name: 'Rodrix Fasteners',
      //   email: 'johnycash@mail.me',
      //   phone: '+23 232 23 23',
      //   shifts: [
      //     {id: 1, name: '🌙  Night Shift'},
      //     {id: 2, name: '🌈  Pride Shift'}
      //   ]
      // },
      // {
      //   id: 8,
      //   active: true,
      //   name: 'Rodrix Fasteners',
      //   email: 'johnycash@mail.me',
      //   phone: '+23 232 23 23',
      //   shifts: [
      //     {id: 1, name: '🌙  Night Shift'},
      //     {id: 2, name: '🌈  Pride Shift'}
      //   ]
      // },
      // {
      //   id: 9,
      //   active: true,
      //   name: 'Rodrix Fasteners',
      //   email: 'johnycash@mail.me',
      //   phone: '+23 232 23 23',
      //   shifts: [
      //     {id: 1, name: '🌙  Night Shift'},
      //     {id: 2, name: '🌈  Pride Shift'}
      //   ]
      // },
      // {
      //   id: 10,
      //   active: true,
      //   name: 'Rodrix Fasteners',
      //   email: 'johnycash@mail.me',
      //   phone: '+23 232 23 23',
      //   shifts: [
      //     {id: 1, name: '🌙  Night Shift'},
      //     {id: 2, name: '🌈  Pride Shift'}
      //   ]
      // }
    ],
    activeCompany: Object,
    showModal: false
  }),
  methods: {
    showAddDriver() {
      this.$root.$emit('ShowSidebar', 'AddDriver')
    }
    // showAddCustomer() {
    //   this.$root.$emit('ShowSidebar', ['AddCustomer', 'width: 700px'])
    // }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
